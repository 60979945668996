import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="switcher"
export default class extends Controller {
  // static targets = ["trigger"]
  // connect() {
  // }

  // triggerTargetConnected(element) {
  //   element.addEventListener("click", this.switch.bind(this));
  // }

  // switch(event) {
  //   this.triggerTargets.forEach(trigger => trigger.classList.toggle("active"));
  // }
}
