import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="chart"
export default class extends Controller {
  static values = { params: { type: Object, default: {} } }

  connect() {
    new Chart(this.element, {
      ...this.paramsValue,
      options: {
        ...this.paramsValue.options,
        plugins: {
          ...this.paramsValue.options.plugins,
          tooltip: {
            ...this.paramsValue.options.plugins.tooltip,
            callbacks: {
              ...this.paramsValue.options.plugins.tooltip.callbacks,
              ...Object.entries(this.paramsValue.options.plugins.tooltip.callbacks).reduce((acc, [key, value]) => {
                acc[key] = eval(value);
                return acc;
              }, {})
            }
          }
        }
      }
    });
  }
}
