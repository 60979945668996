import { Controller } from "@hotwired/stimulus"
import { debounce } from "../helpers/frequency_helpers"

// Connects to data-controller="autosubmit"
export default class extends Controller {
  static values = { delay: { type: Number, default: 0 } }
  connect() {
    if (this.delayValue) {
      this.submit = debounce(this.submit.bind(this), this.delayValue)
    }
  }

  submit() {
    this.element.insertAdjacentHTML('beforeend', '<input type="hidden" name="auto_submit" value="true">')
    this.element.requestSubmit()
    this.element.querySelector('input[name="auto_submit"]').remove()
  }
}
