import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static values = { data: String }
  connect() {
  }

  copy() {
    navigator.clipboard.writeText(this.dataValue)
  }
}
