import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-switcher"
export default class extends Controller {
  connect() {
    this.toggle()
    this.element.addEventListener('change', this.toggle.bind(this))
  }

  toggle() {
    Array.from(this.element.options).forEach(option => {
      let elementToToggle = document.getElementById(option.value)
      if (!elementToToggle) return

      if (option.selected) {
        elementToToggle.classList.remove('hidden')
        Array.from(elementToToggle.querySelectorAll('input, select, textarea')).forEach(input => {
          if (input.dataset.type === 'file') input.type = 'file'
          if (Boolean(input.dataset.required)) {
            if (input.type === 'file') {
              if (!!input.getAttribute('value')) {
                input.removeAttribute('required')
              } else {
                input.setAttribute('required', true)
              }
            } else {
              input.setAttribute('required', true)
            }
          }
        })
      } else {
        elementToToggle.classList.add('hidden')
        Array.from(elementToToggle.querySelectorAll('input, select, textarea')).forEach(input => {
          input.removeAttribute('required')
          input.value = ''
          if (input.dataset.type === 'file') {
            input.setAttribute('value', '')
            input.type = 'hidden'
          }
        })
      }
    })
  }
}
