import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter-badge"
export default class extends Controller {
  static targets = [ "field", "badgeWrapper" ]
  connect() {
  }

  fieldTargetConnected(field) {
    if (field.value === "") return;
    let badge = document.createElement("div");
    badge.classList.add("flex", "items-center", "gap-2", "border-2", "rounded-md", "p-1", "w-auto");
    let label = ""
    let value = ""

    switch (field.tagName) {
      case "INPUT":
        label = field.getAttribute("data-label")
        value = field.value
        break;
      case "SELECT":
        label = field.getAttribute("data-label")
        value = field.options[field.selectedIndex].text
        break;
    }

    badge.innerHTML = `
      <p class="text-xs">${label}: <b>${value}</b></p>
      <a href="${field.getAttribute("data-href")}" class="block size-3">
        <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.92501 14.0613L7.16669 8.81964L12.4084 14.0613L14.0611 12.4085L8.81939 7.16694L14.0611 1.92525L12.4084 0.272461L7.16669 5.51414L1.92501 0.272461L0.272217 1.92525L5.51389 7.16694L0.272217 12.4085L1.92501 14.0613Z" fill="currentColor"/>
        </svg>
      </a>
    `;
    this.badgeWrapperTarget.appendChild(badge);
  }
}
