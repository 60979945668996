import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tab"
export default class extends Controller {
  connect() {
  }

  select(event) {
    let targetId = event.target.getAttribute('data-target')
    let target = document.querySelector(`#${targetId}`)
    let contentElements = document.querySelectorAll('.content')
    let tabElements = document.querySelectorAll('.tab')

    tabElements.forEach(tab => {
      tab.classList.remove('active-tab')
      tab.classList.add('inactive-tab')
    })
    event.target.classList.remove('inactive-tab')
    event.target.classList.add('active-tab')

    contentElements.forEach(tab => {
      tab.classList.add('hidden')
    })
    target.classList.remove('hidden')
  }
}
