import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="masonry"
export default class extends Controller {
  static targets = ["item"]
  connect() {
  }

  itemTargetConnected(item) {
    if (window.innerWidth > 768) {
      let firstColumn = document.querySelector(".firstColumn");
      let secondColumn = document.querySelector(".secondColumn");
      let thirdColumn = document.querySelector(".thirdColumn");
      // let max = Math.min(firstColumn.offsetHeight, secondColumn.offsetHeight, thirdColumn.offsetHeight);
      // if (firstColumn.offsetHeight === max) {
      //   firstColumn.appendChild(item);
      // } else if (secondColumn.offsetHeight === max) {
      //   secondColumn.appendChild(item);
      // } else {
      //   thirdColumn.appendChild(item);
      // }
      if (this.itemTargets.indexOf(item) % 3 === 0) {
        firstColumn.appendChild(item);
      } else if (this.itemTargets.indexOf(item) % 3 === 1) {
        secondColumn.appendChild(item);
      } else {
        thirdColumn.appendChild(item);
      }
    }
  }
}
