import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="flash"
export default class extends Controller {
  static values = { keep: { type: Boolean, default: false } }
  connect() {
    this.element.classList.add("opacity-0", "translate-x-6")
    this.element.offsetHeight
    this.element.classList.remove("translate-x-6")
    this.element.classList.add("translate-x-0", "opacity-100")
    if (!this.keepValue) {
      this.timeout = setTimeout(() => {
        this.dismiss();
      }, 5000);
    }
  }

  async dismiss() {
    this.element.classList.remove("translate-x-0", "opacity-100")
    this.element.classList.add("translate-x-6", "opacity-0")
    setTimeout(() => this.element.remove(), 700)
  }
}
