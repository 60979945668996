import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form"
export default class extends Controller {
  static targets = ["checkBox"]
  connect() {
  }

  resetForm() {
    this.element.reset()
  }

  submit() {
    this.element.requestSubmit()
  }

  atLeastOneCheckBoxChecked(e) {
    if (!this.checkBoxTargets.some(checkBox => checkBox.checked)) {
      this.checkBoxTargets[0].setCustomValidity("Por favor, seleccione al menos una opción.")
      this.element.reportValidity()
      this.checkBoxTargets[0].setCustomValidity("")
      e.detail.formSubmission.stop()
    }
  }
}
