import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-more"
export default class extends Controller {
  static targets = ["content"]
  connect() {
  }

  show(event) {
    this.contentTargets.forEach(content => content.classList.remove("hidden"));
    event.currentTarget.classList.add("hidden");
  }
}
