import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="conditional-text"
export default class extends Controller {
  static values = { trueText: String, falseText: String, targetSelector: String }
  connect() {
    this.toggle()
  }

  toggle() {
    const target = document.querySelector(this.targetSelectorValue)
    target.innerText = this.element.checked ? this.trueTextValue : this.falseTextValue
  }
}
