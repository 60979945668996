import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="text-filter"
export default class extends Controller {
  static targets = [ "label" ]
  connect() {
  }

  filter(event) {
    let value = event.currentTarget.value.toLowerCase();
    this.labelTargets.forEach((label) => {
      if (label.innerText.toLowerCase().includes(value)) {
        label.classList.remove("hidden");
      } else {
        label.classList.add("hidden");
      }
    });
  }
}
