import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flatpickr"
export default class extends Controller {
  static values = { params: { type: Object, default: {} } }

  connect() {
    try {
      flatpickr(this.element, this.paramsValue);
    } catch (error) {
      if (error instanceof ReferenceError) {
        document.addEventListener('DOMContentLoaded', () => {
          flatpickr(this.element, this.paramsValue);
        })
      } else {
        console.error(error)
      }
    }
  }
}
