import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggler"
export default class extends Controller {
  connect() {
  }

  show(event) {
    let elementToShow = document.getElementById(event.params.id)
    elementToShow.classList.remove('hidden')
  }
}
