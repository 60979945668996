import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  static values = { closable: { type: Boolean, default: true } }
  static targets = [ "content" ]
  connect() {
    // if (this.closableValue && !this.element.classList.contains('hidden')) {
    //   document.addEventListener("click", this.closeOutside.bind(this));
    // }
  }

  // closeOutside(event) {
  //   if (!this.contentTarget.contains(event.target)) {
  //     this.hide()
  //     document.removeEventListener("click", this.closeOutside.bind(this));
  //   }
  // }

  hide() {
    this.element.classList.add('hidden')
  }

  remove() {
    this.element.remove()
  }

  handleResponse(e) {
    if (e.detail.success) {
      this.hide()
    } else {
      this.contentTarget.scrollIntoView()
    }
  }
}
