import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="conditional-requirement"
export default class extends Controller {
  static values = { targetSelector: String }
  connect() {
  }

  toggle() {
    const target = document.querySelector(this.targetSelectorValue)
    target.required = this.element.checked
  }
}
