import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="input-preview"
export default class extends Controller {
  static targets = ["input", "preview"]
  connect() {
    this.inputTarget.addEventListener('change', this.preview.bind(this))
  }

  preview() {
    let file = this.inputTarget.files[0]
    this.previewTarget.innerHTML = file.name
  }
}
