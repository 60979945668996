import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails";

// Connects to data-controller="linkedin-review"
export default class extends Controller {
  static values = { elementsToHide: Array }
  static targets = [ "form" ]
  connect() {
  }

  submit(event) {
    event.preventDefault()
    let source = event.target.closest('.verified-button').dataset.source
    let triggableBtn = document.querySelector(`#${source}-btn`)

    if (!this.formTarget.checkValidity()) {
      this.formTarget.reportValidity()
      return
    }

    let csrfToken = document.querySelector(`meta[name="csrf-token"]`).getAttribute('content');
    let formData = new FormData(this.formTarget)
    
    formData.append("review[status]", "draft")
    formData.append("review[rater_data_source]", source)

    fetch(this.formTarget.action, {
      method: this.formTarget.method,
      headers: {
        Accept: 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrfToken,
        'Cache-Control': 'no-cache'
      },
      body: formData
    }).then(response => {
      if (response.status == 422) {
        response.text().then(html => {
          Turbo.renderStreamMessage(html)
        })
      } else {
        triggableBtn.parentElement.submit()
      }
    })
  }
}
