import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="openai"
export default class extends Controller {
  static values = { params: Object }
  connect() {
  }

  ask() {
    const params = this.paramsValue;
    if (params.type === 'catalog') {
      const subject = document.getElementById('catalog-subject').selectedOptions[0].text;
      if (!subject) {
        alert('Please select a subject');
        return;
      }
      params.subject = subject;
    }
    fetch('/ask', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        ...params
      })
    })
    .then(response => response.json())
    .then(data => {
    })
  }
}
