import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tooltip"
export default class extends Controller {
  static targets = ['tooltip']
  static values = { message: String,
                    class: { type: String, default: '' },
                    params: { type: Object, default: {} }}
  connect() {
    try {
      this.instantiatePopper()
    } catch (error) {
      if (error instanceof ReferenceError) {
        document.addEventListener('DOMContentLoaded', () => {
          this.instantiatePopper()
        })
      } else {
        console.error(error)
      }
    }
  }

  instantiatePopper() {
    this.tooltip = this.hasTooltipTarget ? this.tooltipTarget : this.createTooltipElement()
  
      const popperInstance = Popper.createPopper(this.element, this.tooltip, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ],
        ...this.paramsValue
      });
  
      const show = () => {
        // Make the tooltip visible
        this.tooltip.setAttribute('data-show', '');
  
        // Enable the event listeners
        popperInstance.setOptions((options) => ({
          ...options,
          modifiers: [
            ...options.modifiers,
            { name: 'eventListeners', enabled: true },
          ],
        }));
  
        // Update its position
        popperInstance.update();
      }
  
      const hide = () => {
        // Hide the tooltip
        this.tooltip.removeAttribute('data-show');
  
        // Disable the event listeners
        popperInstance.setOptions((options) => ({
          ...options,
          modifiers: [
            ...options.modifiers,
            { name: 'eventListeners', enabled: false },
          ],
        }));
      }
  
      const showEvents = ['mouseenter', 'focus'];
      const hideEvents = ['mouseleave', 'blur'];
  
      showEvents.forEach((event) => {
        this.element.addEventListener(event, show);
      });
  
      hideEvents.forEach((event) => {
        this.element.addEventListener(event, hide);
      });
    }

  createTooltipElement() {
    let tooltip = document.createElement('div');
    tooltip.className = 'tooltip '.concat(this.classValue);
    tooltip.innerText = this.messageValue;
    let arrow = document.createElement('div');
    arrow.id = 'arrow';
    arrow.setAttribute('data-popper-arrow', '');
    tooltip.appendChild(arrow);
    this.element.appendChild(tooltip);
    return tooltip
  }

  changeMessage(event) {
    this.tooltip.innerText = event.params.message
    setTimeout(() => {
      this.tooltip.innerText = this.messageValue
    }, 3000)
  }
}
