import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="character-counter"
export default class extends Controller {
  static targets = [ "input", "counter" ]
  static values = { limit: Number }
  connect() {
    this.count()
  }

  count() {
    const length = this.inputTarget.value.length
    this.counterTarget.textContent = this.limitValue - length
  }
}
