// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

import "@rails/actiontext"

document.addEventListener("turbo:before-fetch-request", (event) => {
  if ((event.detail.fetchOptions.headers["Turbo-Frame"] === "modal" && event.detail.fetchOptions.method === "get") || event.target.dataset.turboFrame === "modal") {
    let frameAnimation = document.getElementById("frame-animation");
    frameAnimation?.classList?.remove("hidden");
  }
})

document.addEventListener("turbo:before-fetch-response", () => {
  let frameAnimation = document.getElementById("frame-animation");
  frameAnimation?.classList?.add("hidden");
});
