import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hide"
export default class extends Controller {
  connect() {
  }

  hide() {
    this.element.classList.add('hidden')
  }
}
