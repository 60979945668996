import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidebar"
export default class extends Controller {
  static targets = ["trigger"]
  connect() {
    this.trigger = document.getElementById("sidebar-close-btn")
    this.trigger.addEventListener("click", this.toggle.bind(this));
  }

  toggle() {
    this.element.classList.toggle("w-64")
    this.element.classList.toggle("w-0")

    if (!this.element.classList.contains("w-0")) {
      document.addEventListener("click", this.closeOutside.bind(this));
    } else {
      document.removeEventListener("click", this.closeOutside.bind(this));
    }
  }

  closeOutside(event) {
    if (!this.trigger.contains(event.target) && !this.element.contains(event.target)) {
      this.element.classList.add("w-0");
      this.element.classList.remove("w-64");
      document.removeEventListener("click", this.closeOutside.bind(this));
    }
  }    
}
