import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="conditional-disablement"
export default class extends Controller {
  static targets = ["booleanTrigger", "elementToDisable"]
  connect() {
  }

  toggle() {
    if (!this.hasBooleanTriggerTarget || !this.hasElementToDisableTarget) return

    if (this.booleanTriggerTarget.tagName === 'INPUT') {
      if (this.booleanTriggerTarget.type === 'checkbox') {
        if (this.booleanTriggerTarget.checked) {
          this.elementToDisableTarget.removeAttribute('required')
          this.elementToDisableTarget.setAttribute('disabled', 'disabled')
          this.elementToDisableTarget.value = ""
        } else {
          this.elementToDisableTarget.removeAttribute('disabled')
          this.elementToDisableTarget.setAttribute('required', 'required')
        }
      }
    } else if (this.booleanTriggerTarget.tagName === 'SELECT') {
      if (this.booleanTriggerTarget.value !== '') {
        this.elementToDisableTarget.setAttribute('disabled', 'disabled')
        this.elementToDisableTarget.value = this.booleanTriggerTarget.options[this.booleanTriggerTarget.selectedIndex].text
      } else {
        this.elementToDisableTarget.removeAttribute('disabled')
        this.elementToDisableTarget.value = ''
      }
    }
  }
}
