import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="frame-loader"
export default class extends Controller {
  connect() {
  }

  show(event) {
    let turboFrameElement = event.params.selector ? document.querySelector(event.params.selector) : event.target.closest("turbo-frame")

    for (let i = 0; i < turboFrameElement.children.length; i++) {
      turboFrameElement.children[i].style.display = "none"
    }

    turboFrameElement.appendChild(this.skeletonElement())
  }

  skeletonElement(){
    let element = document.createElement("div")
    element.classList.add("shadow", "rounded-md", "p-4", "w-full", "max-w-xl", "mx-auto")
    element.innerHTML = `<div class="animate-pulse flex space-x-4">
                           <div class="flex-1 space-y-6 py-1">
                             <div class="h-2 bg-grey-light rounded"></div>
                             <div class="space-y-3">
                               <div class="grid grid-cols-3 gap-4">
                                 <div class="h-2 bg-grey-light rounded col-span-2"></div>
                                 <div class="h-2 bg-grey-light rounded col-span-1"></div>
                               </div>
                               <div class="h-2 bg-grey-light rounded"></div>
                             </div>
                           </div>
                         </div>`;
    return element
  }
}
