import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dynamic-select"
export default class extends Controller {
  static targets = ["firstSelect", "secondSelect"]

  change(event) {
    let trigger = event.target
    let targetName = trigger.getAttribute('data-target')
    if (!this.targets.has(targetName)) return

    let url = trigger.getAttribute('data-url')
    let target = eval(`this.${targetName}Target`)
    let param = trigger.getAttribute('data-param')
    let value = trigger.value

    if (value == "") return

    let params = new URLSearchParams()
    params.append(param, trigger.value)
    params.append("target", target.id)

    fetch(`${url}?${params}`, {
      method: "GET",
      headers: {
        Accept: 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': this.getMetaContent('csrf-token'),
        'Cache-Control': 'no-cache',
      }
    }).then(r => r.text()).then(html => Turbo.renderStreamMessage(html))
  }

  getMetaContent(name) {
    return document.querySelector(`meta[name="${name}"]`).getAttribute('content');
  }
}
