import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="check-box"
export default class extends Controller {
  static values = { onlyOneChecked: { type: Boolean, default: false } }
  static targets = ["checkBox"]
  connect() {
  }

  checkBoxTargetConnected(element) {
    if (this.onlyOneCheckedValue) {
      element.addEventListener("click", this.uncheck.bind(this));
    }
  }

  uncheck(event) {
    let currentCheckBoxChecked = event.currentTarget.checked;
    this.checkBoxTargets.forEach(checkBox => checkBox.checked = false);
    event.currentTarget.checked = currentCheckBoxChecked;
  }
}
