import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="rating"
export default class extends Controller {
  static targets = ["1", "2", "3", "4", "5", "currentRating", "valueField"]
  connect() {
    this.shineStars(this.valueFieldTarget.value || this.valueFieldTarget.getAttribute("value"));
    if (this.valueFieldTarget.value === "0") {
      this.valueFieldTarget.value = ""
    }
  }

  handleRating(event) {
    if (this["1Target"].contains(event.target)) {
      this.shineStars(1)
    } else if (this["2Target"].contains(event.target)) {
      this.shineStars(2)
    } else if (this["3Target"].contains(event.target)) {
      this.shineStars(3)
    } else if (this["4Target"].contains(event.target)) {
      this.shineStars(4)
    } else if (this["5Target"].contains(event.target)) {
      this.shineStars(5)
    }
  }

  shineStars(limit) {
    for (let i = 2; i <= 5; i++) {
      this[`${i}Target`].classList.remove("text-star");
    }
    if (this.currentRatingTarget.value === limit) {
      this.currentRatingTarget.value = 1;
    } else {
      for (let i = 1; i <= limit; i++) {
        this[`${i}Target`].classList.add("text-star");
      }
      this.currentRatingTarget.value = limit;
    }
    this.valueFieldTarget.value = this.currentRatingTarget.value;
  }
}
