import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal-trigger"
export default class extends Controller {
  static values = { modalSelector: String, modalContentSelector: String }
  connect() {
  }

  open() {
    const target = document.querySelector(this.modalSelectorValue)
    target.classList.remove('hidden')
  }

  closeOutside(event) {
    if (event.target.dataset.controller !== undefined && event.target.dataset.controller.includes('modal-trigger')) return

    const modalContent = document.querySelector(this.modalContentSelectorValue)
    if (!modalContent.contains(event.target) && !event.target.isEqualNode(this.element)) {
      let modal = document.querySelector(this.modalSelectorValue)
      modal.classList.add('hidden')
    }
  }
}
