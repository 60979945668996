import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="alert"
export default class extends Controller {
  static values = { message: String }
  connect() {
    let modal = document.createElement('div')
    modal.setAttribute('tabindex', '-1')
    modal.setAttribute('aria-hidden', 'true')
    modal.classList.add('hidden', 'p-4', 'md:p-8', 'overflow-y-auto', 'overflow-x-hidden', 'fixed', 'top-0', 'right-0', 'left-0', 'z-50', 'flex', 'justify-center', 'items-center', 'w-full', 'h-full', 'max-h-full')

    let background = document.createElement('div')
    background.classList.add('fixed', 'inset-0', 'bg-grey-dark', 'bg-opacity-75', 'transition-opacity')

    let modalContent = document.createElement('div')
    modalContent.classList.add('relative', 'w-full', 'max-w-xs', 'max-h-full', 'p-3', 'bg-white', 'rounded-lg')

    let buttonsWrapper = document.createElement('div')
    buttonsWrapper.classList.add('flex', 'justify-end', 'gap-2')

    let text = document.createElement('p')
    text.classList.add('text-xs', 'mb-3', 'text-justify')
    text.innerHTML = this.messageValue

    let cancelButton = document.createElement('button')
    cancelButton.classList.add('bg-negative', 'hover:bg-negative-light', 'btn', 'w-auto', 'py-1.5', 'px-4')
    cancelButton.innerText = "Cancel"

    let confirmButton = document.createElement('button')
    confirmButton.classList.add('bg-primary', 'hover:bg-primary-light', 'btn', 'w-auto', 'py-1.5', 'px-4')
    confirmButton.innerText = "Ok"

    cancelButton.addEventListener('click', () => {
      modal.classList.add('hidden')
    })

    confirmButton.addEventListener('click', () => {
      this.element.requestSubmit()
    })

    this.element.addEventListener('click', (e) => {
      e.preventDefault()
      modal.classList.remove('hidden')
    })

    buttonsWrapper.appendChild(cancelButton)
    buttonsWrapper.appendChild(confirmButton)

    modalContent.appendChild(text)
    modalContent.appendChild(buttonsWrapper)
    modal.appendChild(background)
    modal.appendChild(modalContent)
    document.body.appendChild(modal)
  }
}
